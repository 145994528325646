<template>
    <div class="page page--services">
        <Services />     
    </div>
</template>


<script>
import Services from '@/components/pages/Services.vue'

export default {    
  components: {    
    Services   
  }  
}
</script>